@import "@vmf/components/src/assets/style/_index.scss";























































































































































































































































































































































.goods-spec {
    > table {
        width: 100%;
        border-collapse: collapse;

        tr {
            > th,
            > td {
                border: 1px solid $color-border;
            }
        }
    }
}
