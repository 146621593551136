@import "@vmf/components/src/assets/style/_index.scss";



































































































































































































































































































.goods-spu {
    > .spec {
        border: 1px solid $color-border;
        margin-bottom: $margin;

        > .header {
            padding-left: $padding;
            padding-right: $padding;
            padding-top: 18px;
            background-color: $gray1;
        }
        > .body {
            padding: $padding;
            > .sku-item {
                /*display: inline-block;*/
                vertical-align: top;
                display: inline-flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin-right: $margin;
            }
        }
    }
}
